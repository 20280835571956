import React, { useEffect, useState } from "react";
import TempwareSidebar from '../components/Tempware/TempwareSidebar'
import TempwareHeader from '../components/Tempware/TempwareHeader'
import TempwareDashboard from "../components/Tempware/Pages/TempwareDashboard";
import TempwareDevice from '../components/Tempware/Pages/TempwareDevice'
import TempwareAlert from "../components/Tempware/Pages/TempwareAlert";
import { Routes, Route, Link, useRoutes } from "react-router-dom";

const TempwareLayout = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
      setIsDarkMode(prevMode => !prevMode);
  };
  const tempwareRoutes = (
    <Routes>
    <Route index element={<TempwareDashboard isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}/>} /> 
   <Route path="/device" element={<TempwareDevice isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}/>} /> 
   <Route path="/alert" element={<TempwareAlert isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}/>} /> 
    {/* <Route index element={<TempwareDevice />} /> */}
    </Routes>
  );
    return (
      <>
        <div className={`tempwareBody ${!isDarkMode ? 'dark' : 'light'}`}>
        {/* <div className={`tempwareBody`}> */}
        <section class="pageOuter">
        <div class="d-flex align-items-start gap-lg-5 gap-md-4 gap-4">
        <TempwareSidebar isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}/>
        {tempwareRoutes}
        </div>
        </section>
        </div>
      </>
    );

};

export default TempwareLayout;
// import React from "react";
// import TempwareSidebar from '../components/Tempware/TempwareSidebar'
// import TempwareDashboard from "../components/Tempware/Pages/TempwareDashboard";
// import TempwareDevice from '../components/Tempware/Pages/TempwareDevice'
// import { Routes, Route } from "react-router-dom";

// const TempwareLayout = () => {
//   return (
//     <>
//       <div className="tempwareBody">
//         <section className="pageOuter">
//           <div className="d-flex align-items-start gap-lg-5 gap-md-4 gap-4">
//             <TempwareSidebar />
//             {/* <div className="contentArea"> */}
//               {/* Define the dynamic routes */}
//               <Routes>
//                 {/* Default route (dashboard) */}
//                 <Route index element={<TempwareDashboard />} />
                
//                 <Route path="/device" element={<TempwareDevice />} />
//               </Routes>
//             {/* </div> */}
//           </div>
//         </section>
//       </div>
//     </>
//   );
// };

// export default TempwareLayout;
